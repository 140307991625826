import { parseISO } from 'date-fns'
import { STATUS_NOVEL_PUBLISHED } from '~/types/constant_novels'
import type { Database } from '~/types/supabase'

export type Profile = {
  name: string
}

export type NovelFeed = {
  id: number
  title: string
  image: string
  description: string
  associatedNames: string[]
  genre: string[]
  tags: string[]
  language: string
  chapterId: number
  chapterTitle: string
  profile?: Profile
  createdAt: Date
  updatedAt: Date
}

export const useNovelFeed = defineStore('novelFeed', () => {
  const toast = useToast()
  const sb = useSupabaseClient<Database>()

  const feeds = ref<NovelFeed[]>([])
  const perPage = ref(8)
  const totalData = ref(0)
  const pending = ref(false)

  const getNovelFeed = async ({ page = 0 }: { page: number }) => {
    try {
      pending.value = true
      const { from, to } = getPagination(page, perPage.value)

      const res = await sb
        .from('chapters')
        .select(`id, title, created_at, updated_at, profiles(name), novels(id, title, image, description, associated_names, genre, tags, language)`, {
          count: 'exact',
        })
        .eq('status', STATUS_NOVEL_PUBLISHED)
        .order('created_at', { ascending: false })
        .range(from, to)

      if (res.error) {
        throw res.error
      }

      totalData.value = res.count || 0
      feeds.value = res.data.map(val => ({
        id: val.novels?.id,
        title: val.novels?.title,
        image: val.novels?.image,
        description: val.novels?.description,
        associatedNames: val.novels?.associated_names,
        genre: val.novels?.genre,
        tags: val.novels?.tags,
        language: val.novels?.language,
        chapterId: val.id,
        chapterTitle: val.title,
        profile: {
          name: val.profiles?.name,
        },
        createdAt: parseISO(val.created_at),
        updatedAt: parseISO(val.updated_at),
      } as NovelFeed))
    } catch (error) {
      toast.add({
        title: 'Failed to get feed',
        description: String(error),
        color: 'red',
      })
    } finally {
      pending.value = false
    }
  }

  return {
    // state
    feeds,
    perPage,
    totalData,
    pending,

    // actions
    getNovelFeed,
  }
})
